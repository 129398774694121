.transform-underline-input-box {
    position: relative;
    margin-bottom: 20px;
  
    .underline-input {
      padding-right: 40px;
  
      &:focus,
      &.active {
        + .input-label {
          display: block;
        }
  
        &::placeholder {
          color: transparent;
        }
      }
  
      &::placeholder {
        font-size: 13px;
        font-weight: 600;
      }
    }
    
    .input-label {
      display: none;
      position: absolute;
      top: -15px;
      left: 0;
      color: #afafaf;
      font-size: 10px;
      font-weight: 600;
    }
  
    .right-box {
      position: absolute;
      display: flex;
      align-items: center;
      top: 4px;
      right: 0;
  
      .check-icon {
        .anticon-check {
          bottom: 13px;
  
          svg {
            stroke: #00ad7b;
            stroke-width: 80px;
          }
        }
      }
  
      .lock-icon {
        padding-right: 10px;
      }
  
      .lock-icon,
      .info-tooltip {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
  