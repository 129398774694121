// 훈련 템플릿 등록/수정 모달
.examtemplate-add-edit-modal {
  .ant-modal-header {
    padding: 30px 45px 28px !important;
  }

  .ant-modal-body {
    max-height: calc(80vh - 10em);
    overflow: auto;
  }

  .ant-modal-footer {
    text-align: right !important;
    width: 100%;
    padding: 13px !important;
    box-shadow: 0 -5px 30px -15px #aaa;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
  }

  .sub-title {
    margin-top: 10px;
    font-size: 15px;
  }

  .mail-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-checkbox-wrapper {
      font-size: 13px;
    }
  }

  .modal-item {
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }

    .ant-radio-group {
      > span {
        display: flex;
        width: 100%;

        .examtype-button {
          height: 230px !important;
          line-height: 100%;
          padding: 5px;

          &.multiple {
            .examtype-item {
              width: 204px;
            }
          }

          &.ant-radio-button-wrapper-checked {
            color: #000;
          }

          span {
            &:last-child {
              display: flex !important;
              height: 100%;
              padding: 0 !important;
            }
          }

          .examtype-item {
            margin-left: 10px;
            padding: 20px 18px;

            &.active {
              background-color: #14ae96;
              color: #fff;
              border-radius: 6px;

              .examtype-content {
                color: #fff;
              }

              // .examtype-icon {
              //   &.warning {
              //     background: url(/img/exam/template/exam_warning_w.png) no-repeat center;
              //   }

              //   &.file {
              //     background: url(/img/exam/template/exam_file_w.png) no-repeat center;
              //   }

              //   &.cheat {
              //     background: url(/img/exam/template/exam_cheat_w.png) no-repeat center;
              //   }

              //   &.phishing {
              //     background: url(/img/exam/template/exam_phishing_w.png) no-repeat center;
              //   }
              // }
            }

            &:first-child {
              margin-left: 0;
            }

            .examtype-icon {
              display: block;
              width: 39px;
              height: 32px;

              // &.warning {
              //   background: url(/img/exam/template/exam_warning.png) no-repeat center;
              // }

              // &.file {
              //   background: url(/img/exam/template/exam_file.png) no-repeat center;
              // }

              // &.cheat {
              //   background: url(/img/exam/template/exam_cheat.png) no-repeat center;
              // }

              // &.phishing {
              //   background: url(/img/exam/template/exam_phishing.png) no-repeat center;
              // }
            }

            .examtype-title {
              margin-top: 18px;
              font-size: 18px;
              font-weight: 700;
            }

            .examtype-sub-title {
              margin-top: 10px;
              font-size: 13px;
              font-weight: 400;
            }

            .examtype-content {
              width: 100%;
              line-height: 18px;
              margin-top: 15px;
              color: #6b6b6b;
              font-size: 12px;
              font-weight: 400;
              // word-break: keep-all;

              &.ja {
                font-size: 10px;
              }
            }
          }
        }
      }
    }

    .attach-result {
      .attach-result-button {
        width: 140px;
        height: 40px;
        line-height: 34px;
        margin: 5px 0 0 10px;
        padding: 2px 10px;
        text-align: center;
        border: 1px solid #ddd;
        border-radius: 5px;
        cursor: pointer;
      }
    }

    .input-row {
      display: flex;

      .input-block {
        flex: 1;
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }

        &.flex-large {
          flex: 2.5;
        }
      }

      .form-field-wrap {
        margin-left: 0;
      }

      .send-msg {
        position: absolute;
        margin: 3px;
        font-size: 12px;
        color: #00ad7b;
      }
    }

    .phishing-body-iframe,
    .phishing-result-iframe {
      display: none;
      width: 100%;
      height: auto;
      min-height: 400px;
      margin-top: 5px !important;
      padding: 25px 0;
      border: 1px solid #dddddd;
      border-radius: 5px;
      background-color: #ffffff;

      &.on {
        display: block;
      }
    }

    .phishing-result-iframe {
      margin-top: 16px !important;
    }
  }
}

// 첨부파일 가져오기 메뉴
.attach-select-menu {
  &.phishing {
    margin-top: 10px;
  }

  .ant-select-selector {
    height: 40px !important;
    padding: 0 !important;
    border-radius: 5px !important;
  }

  .ant-select-item {
    padding: 12px 16px;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    line-height: 100% !important;
  }

  .ant-select-dropdown {
    .ant-select-item-option-selected {
      font-weight: normal !important;
      background-color: #00000013 !important;
    }
  }

  .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }

  .attach-no {
    width: 40px;
    font-weight: 700;
  }

  .attach-value {
    margin-right: 10px;
    text-indent: 0;

    .attach-icon {
      display: flex;
      align-items: center;
      width: 20px;
      height: 20px;
    }

    .ant-tag {
      margin-right: 5px;
      font-size: 11px;
      text-align: center;
      border-radius: 3px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }

    .temp-type-label {
      font-weight: 700;
    }

    .attach-label {
      margin-right: 2px;
    }
  }
}

// 실행 결과 이미지
.result-image {
  img {
    width: 250px;
  }
}

// 훈련 템플릿 수정 모달 - 첨부파일 가져오기 팝오버
.template-attach-popover {
  width: 480px;

  .attach-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .attach-title {
      font-weight: bold;
    }
  }
}

// 훈련 템플릿 수정 모달 - 메일 내용 팝오버
.template-body-popover {
  width: 280px;

  .info-wrap {
    font-size: 12px;
    line-height: 1.8;
    color: #6e6e6e;

    .info-title {
      padding-bottom: 5px;
      font-size: 13px;
      font-weight: bold;
    }

    .info-content {
      padding-left: 10px;
    }

    .reserve-column-list {
      padding: 10px 0;

      .reserve-text {
        color: #afafaf;
        font-size: 12px;

        .reserve-title {
          display: inline-block;
          width: 50px;
          margin-right: 5px;

          &.ja {
            width: 80px;
            font-size: 11px;
          }
        }

        .reserve-content {
          letter-spacing: 0.2px;
        }
      }
    }
  }
}
