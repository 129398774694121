.dashboard-page {
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  text-align: center;

  .dashboard {
    width: 1460px;
    min-width: 1460px;
    height: 100%;
    margin: 45px auto 0 auto;

    .left-area {
      display: block;
      box-sizing: border-box;

      .left {
        float: left;
        position: relative;
        width: 1110px;
        // width: calc(100% - 350px);
        height: 100%;
        box-sizing: border-box;

        .board-row {
          display: flex;
          width: 100%;
          height: auto;
          margin-bottom: 30px;
          text-align: left;
          box-sizing: border-box;

          .board {
            display: inline-block;
            margin-right: 19px;
            padding: 20px;
            background-color: #fff;
            border: 1px solid #e2e2e2;
            box-sizing: border-box;
            border-radius: 5px;

            &.top {
              width: 368px;
              height: 320px;
            }

            &.middle {
              width: 527px;
              height: 350px;
            }

            &.bottom {
              width: 100%;
              height: 350px;
            }
          }

          .board-exam {
            display: inline-block;
            width: 375px;
            height: 320px;
            margin-right: 10px;
            padding: 0px;
            box-sizing: border-box;
            border-radius: 5px;

            .statistics-row {
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              .statistics-item {
                display: inline-block;
                width: 165px;
                height: 155px;
                color: #fff;
                box-sizing: border-box;
                border-radius: 5px;

                &:first-child {
                  margin-right: 15px;
                }

                &.all {
                  background-color: #14ae96;
                }

                &.reserve {
                  background-color: #40a1be;
                }

                &.ing {
                  background-color: #4d6f80;
                }

                &.end {
                  background-color: #757575;
                }

                .statistics-title {
                  display: block;
                  // margin: 35px 0 15px;
                  font-weight: 600;
                  font-size: 16px;
                  color: #fff;
                  text-align: center;

                  padding: 15px 0 15px;
                  border-bottom: 1px solid #f5f5f5;
                }

                .number {
                  display: block;
                  margin-top: 20px;
                  font-weight: 700;
                  font-size: 36px;
                  text-align: center;

                  span {
                    font-size: 18px;
                    margin-left: 5px;
                  }
                }
              }
            }
          }

          .board-target {
            display: inline-block;
            width: 545px;
            height: 350px;
            margin-right: 19px;
            padding: 0px;
            box-sizing: border-box;
            border-radius: 5px;

            .target-row {
              margin-bottom: 10px;

              .target-item {
                display: inline-block;
                width: 262px;
                height: 170px;
                padding: 20px;
                background-color: #fff;
                border: 1px solid #e2e2e2;
                box-sizing: border-box;
                border-radius: 5px;

                &:first-child {
                  margin-right: 20px;
                }
              }
            }
          }

          .exam-list-table {
            font-size: 13px;
            text-align: center;

            .ant-row {
              height: 40px;
              line-height: 32px;
              margin: 8px 0 0 0;
              padding: 3px 10px;
              color: #333;
              border: 1px solid #e2e2e2;
              border-radius: 5px;
            }

            .table-header {
              font-weight: 700;

              .ant-row {
                height: 35px;
                border: none;
              }
            }

            .gutter-row {
              width: 80px;
              padding: 0 5px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .exam-name {
              flex: 1;
              text-align: left;
            }

            .exam-period {
              width: 180px !important;
            }
          }

          .exam-no-data {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80%;
            margin: auto;
            color: #888;
            font-size: 15px;
            text-align: center;
          }
        }
      }
    }

    .right-area {
      display: block;
      box-sizing: border-box;

      .right {
        float: right;
        position: relative;
        width: 350px;
        height: 1080px;
        padding: 20px;
        background-color: #fff;
        text-align: left;
        box-sizing: border-box;
        border: 1px solid #e2e2e2;
        border-radius: 5px;

        .board {
          width: 100%;
          display: inline-block;
          margin-top: 20px;
          padding: 10px 0 25px 0;
          border-bottom: 1px solid #e2e2e2;

          &:first-child {
            margin-top: 0;
          }

          &.top5 {
            border-bottom: 0;
          }

          .content {
            margin-top: 20px;

            .license-info {
              margin-bottom: 10px;
              font-size: 15px;
              font-weight: 700;
              color: #00ad7b;
            }
          }
        }
      }
    }
  }

  .content-title {
    display: inline-block;
    color: #333;
    font-size: 18px;
    font-weight: bold;
  }

  .content-row {
    display: flex;
    align-items: center;
    line-height: 20px;
    margin: 5px 0 0 10px;
    color: #333;
    font-size: 12px;

    &:first-child {
      margin-top: 0;
    }

    &.no-license {
      min-height: 120px;
      color: #888;
      font-size: 13px;
    }

    .content-text {
      width: 120px;
      font-weight: 700;

      i {
        margin-right: 5px;
        color: #00ad7b;
        font-style: normal;
      }
    }
  }
}

// 공지사항 모달
.notice-modal {
  .notice-title {
    margin: 10px 0 40px;
    font-size: 20px;
    font-weight: 700;
  }

  .notice-content {
    max-height: calc(80vh - 10em);
    overflow: auto;
    font-size: 15px;
    white-space: pre-wrap;
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    padding: 0 40px 20px !important;
    color: #757575;

    div {
      cursor: pointer;
    }
  }
}
