// select item
.ant-select-dropdown {
  .ant-select-item-option-selected {
    background-color: white !important;
  }
  .ant-select-item-option-active {
    background-color: #dfdfdf !important;
  }

  .ant-select-item {
    padding: 8px 10px;
  }

  &.disabled {
    cursor: default;
    .ant-select-item {
      pointer-events: none;
      opacity: 0.45;
    }
  }
}

.form-field-wrap {
  position: relative;
  width: 100%;

  input {
    width: 100%;
    height: 40px;
    border: 1px solid #dfdfdf;
    text-indent: 10px;

    margin-top: 5px;
    border-radius: 5px;
    &::placeholder {
      color: #dfdfdf;
    }
  }

  .ant-select {
    width: 100%;
    height: 100%;

    &.ant-select-multiple {
      margin-top: 5px;
    }

    .ant-select-selector {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px !important;
      border: 1px solid #dfdfdf !important;
      text-indent: 10px;
      box-shadow: none !important;
      padding: 0 !important;
      margin-top: 5px;
      border-radius: 5px !important;
      &::placeholder {
        color: #dfdfdf;
      }

      &:hover {
        border-color: #dfdfdf;
      }

      .ant-select-selection-item {
        line-height: 260%;
      }
    }

    .ant-select-arrow {
      top: 26px;
      right: 20px;
    }

    .ant-select-open {
      border-color: #dfdfdf;
    }
  }

  .form-wrap {
    position: relative;
    width: 100%;

    .right-box {
      position: absolute;
      align-items: center;
      top: 12px;
      right: 15px;
      cursor: pointer;
    }

    .validate-error {
      border-color: #ff1744 !important;
      background: #fffafa !important;
    }

    .warning {
      .ant-select-selector {
        border: 1px solid #ff1744 !important;
      }

      .ant-select-selection-placeholder {
        color: #ff1744 !important;
      }
    }
  }
}
.ant-switch-checked {
  background-color: #00bfa5;
}
