.date-picker-box {
  position: relative;
  display: flex;
  flex-direction: column;

  // date-picker
  .ant-picker.date-picker {
    width: 100%;
    height: 40px;
    margin-top: 5px;
    border-radius: 5px;

    &.ant-picker-focused {
      border-color: #dfdfdf;
      box-shadow: none;
    }

    &:hover {
      border-color: #dfdfdf;
    }

    &.error {
      border-color: #ff1744;
    }

    &.disabled {
      pointer-events: none;

      .ant-picker-suffix {
        display: none;
      }
    }
  }

  .extra-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;

    .date-detail-text {
      color: #3cb482;
      font-size: 12px;
      text-align: right;
    }

    .right {
      text-align: right;
    }
  }
}

// date-picker dropdown
.ant-picker-dropdown {
  .ant-picker-header-view {
    button {
      &:hover {
        color: #11a991;
      }
    }
  }
  .ant-picker-today-btn {
    color: #11a991;
  }

  .ant-picker-cell-inner {
    &::before {
      border: 1px solid #11a991 !important;
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background: #11a991;
    }
  }
}

// 시작일자, 종료일자 가로 정렬
.date-picker-area {
  display: flex;

  .date-picker-item {
    display: flex;
    flex-direction: column;
    flex: 1;

    &:first-child {
      margin-right: 10px;
    }
  }
}
