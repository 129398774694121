// 교육 템플릿 등록 모달
.edu-template-add-modal {
  .video-add-area {
    display: flex;
    align-items: flex-end;

    .edu-item {
      margin-right: 5px;
    }

    .add-video-btn {
      width: 65px;
      height: 40px;
      color: #fff;
      background-color: #838383;
      border-radius: 5px;
      border: none;
      outline: none;
    }
  }

  .video-list-box {
    height: 152px;
    margin: 5px 0 10px;
    border-radius: 3px;
    background-color: #f9f9f9;
    box-shadow: inset 0 0 0 1px #e4e4e4, 0 0 0 0 transparent;
    overflow-y: auto;

    .video-list {
      padding: 5px 10px;

      .video-list-item {
        padding-bottom: 5px;

        &:last-child {
          padding-bottom: 0;
        }

        .video-title {
          font-weight: 700;

          .dot {
            font-weight: 700;
            padding-right: 5px;
          }

          .running-time {
            color: #838383;
            font-weight: normal;
            font-size: 13px;
          }
        }

        .video-url {
          padding-left: 8px;
        }
      }
    }
  }

  .file-input-area {
    width: 100%;
    height: 60px;
    margin-top: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #d6d6d6;
    border-radius: 5px;

    label {
      max-width: calc(100% - 20px);
      padding: 0 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #919191;
      font-size: 13px;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}

// 영상보기 모달
.edu-template-video-modal {
  .video-player-box {
    position: relative;

    .video-header {
      position: absolute;
      top: -30px;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      color: #eee;
      background-color: #000;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      z-index: 2;

      .video-title {
        font-size: 15px;
      }

      .video-sub-title {
        font-size: 13px;
      }

      .video-header-right {
        font-size: 13px;
        text-align: right;
      }
    }

    .video-player {
      margin-top: 35px;
    }
  }
}
