// 교육 요약
.edu-summary {
  .summary-list-header {
    height: auto !important;
    // height: 53px !important;
    // max-height: 119px !important;
  }

  .search-icon {
    opacity: 0.6;

    &.active {
      opacity: 1;
    }
  }

  .summary-list-content {
    // position: relative;
    height: calc(100% - 53px) !important;

    &.extend {
      height: calc(100% - 119px);
    }
  }

  .bg-box {
    margin: 10px 0;
    font-weight: 700;

    .bg-inner {
      padding: 8px 12px;

      .inner-row {
        display: flex;
        justify-content: space-between;

        &.li {
          padding-top: 5px;

          .value {
            color: #888;
          }
        }
      }

      &.top {
        background-color: #e2e2e2;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &.bottom {
        background-color: #ececec;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .summary-item-wrap {
    .inner-bottom {
      .row {
        margin-top: 5px;
      }

      img {
        margin-right: 5px;
      }
    }
  }
}

// 교육 리스트
.edu-content-wrap {
  height: calc(100% - 50px);

  .edu-percent {
    display: inline-block;
    width: 51px;
    line-height: 23px;
    color: #000;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    background-color: #e2e2e2;
    border-radius: 20px;
  }
}

.edu-detail {
  .grey-text {
    margin-left: 5px;
    color: #9c9c9c;
  }

  .quiz-list {
    padding-left: 15px;
  }

  .edu-contents-list {
    margin: 10px 0 23px;
    padding: 0 12px;
    font-size: 13px;
    border-left: 2px solid #eaeaea;
  }

  .edu-thumbnail {
    position: relative;
    width: 280px;
    height: 160px;
    margin: 5px 0 10px;

    .thumbnail {
      width: 100%;
      height: 100%;
    }

    .edu-thumbnail-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  .chart-legend {
    color: #333;
    font-size: 13px;

    .total-message {
      width: 92px;
    }
    .message {
      width: 80px;
    }
  }

  .quiz-table {
    width: 90%;
    font-size: 13px;

    .quiz-table-header {
      background-color: #ececec;

      th {
        padding: 5px 10px;
        border: 1px solid #fff;
      }
    }

    .quiz-table-content {
      background-color: #f8f8f8;

      td {
        padding: 5px 10px;
        border: 1px solid #fff;
      }
    }

    .answer {
      width: 100px;
    }
  }
}

.form-wrap {
  .ant-select {
    &.multi-select {
      .ant-select-selector {
        height: 100%;
        margin: 0;
        padding: 5px 35px 5px 7px;
        text-indent: 0;

        .ant-select-selection-search {
          margin-inline-start: 0;
        }
      }

      .ant-select-arrow {
        top: 50%;
      }

      .ant-tag-close-icon {
        margin-right: 0;
      }
    }
  }
}
