@import 'styles/common.scss';

body {
  min-width: 1600px;
  overflow-x: auto;
  overflow-y: hidden;

  &.ko {
    a,
    button,
    div,
    input,
    pre,
    textarea {
      font-family: 'Malgun Gothic', Arial, Helvetica !important;
      letter-spacing: -1px;
    }
  }

  &.en {
    a,
    button,
    div,
    input,
    pre,
    textarea {
      font-family: Arial, Helvetica !important;
      letter-spacing: 0;
    }
  }

  &.ja {
    a,
    button,
    div,
    input,
    pre,
    textarea {
      font-family: 'MEIRYO', Arial, Helvetica !important;
      letter-spacing: -1px;
    }
  }

  #root {
    height: 100%;
  }

  .App {
    display: flex;
    flex-direction: column;
    line-height: 1.43;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-flex-start {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.flex-row-center {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.flex-start {
  align-items: flex-start !important;
}

.flex-1 {
  flex: 1;
}

.display-none {
  display: none !important;
}

.inline-block {
  display: inline-block;
}

.display-block {
  display: block !important;
}

.display-flex {
  display: flex !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.full-area {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.ellipsis {
  display: block !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
}

.bold {
  font-weight: bold;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-16 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.margin-0 {
  margin: 0px !important;
}

.ml-1 {
  margin-left: 1px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-9 {
  margin-left: 9px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-27 {
  margin-left: 27px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-13 {
  margin-top: 13px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-22 {
  margin-bottom: 22px !important;
}

.mtb-5 {
  margin: 5px 0;
}

.mtb-12 {
  margin: 12px 0;
}

.vertical-top {
  vertical-align: top;
}

//공통 버튼

.common-button-brand {
  border-radius: 23px !important;
  min-width: 126px !important;
  height: 45px;
  color: white !important;
  padding: 11px 30px !important;
  background-color: #11a991 !important;
  border: 1px solid #11a991 !important;

  &:hover {
    box-shadow: 1px 1px 5px $grey700;
  }
}

.common-button-gray {
  border-radius: 23px !important;
  min-width: 126px !important;
  height: 45px;
  color: white !important;
  padding: 11px 30px !important;
  background-color: #5e5e5e !important;
  border: 1px solid #5e5e5e !important;

  &:hover {
    box-shadow: 1px 1px 5px $grey700;
  }

  &.disable {
    pointer-events: none;
    opacity: 0.5;
  }
}

.common-button-border {
  border-radius: 23px !important;
  min-width: 130px !important;
  height: 45px;
  background-color: transparent !important;
  border: 1px solid #bbbbbb !important;
  color: #bbbbbb !important;
  padding: 11px 30px !important;
}

.license-button-gray {
  min-width: 95px !important;
  border-radius: 20px !important;
  height: 34px !important;
  background-color: #5e5e5e !important;
  color: white !important;
  font-size: 12px;
}

.license-button-brand {
  min-width: 95px !important;
  border-radius: 20px !important;
  height: 34px !important;
  background-color: #11a991 !important;
  color: white !important;
  font-size: 12px !important;
}

.share-button-gray {
  min-width: 90px !important;
  border-radius: 20px !important;
  height: 42px !important;
  background-color: #5e5e5e !important;
  color: white !important;
  font-size: 12px;
}

.dropdown-arrow-area {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  .dropdown-image {
    margin-right: 3px;
  }
}

.space {
  right: 2px;
}
// 훈련템플릿의 첨부파일 정보
.attach-cell-popup {
  .attach-cell-popup-each {
    display: flex;
    padding: 3px;

    .attach-cell-popup-title {
      width: 75px;
      text-align: left;
    }

    .attach-cell-popup-content {
      display: flex;
      align-items: center;
    }
  }
}

// 팝업

.attach-popup {
  .func {
    text-align: left;
    width: 320px;
  }

  .restrict {
    text-align: left;
    width: 450px;
  }
}
