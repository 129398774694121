@import '../../styles/variable.scss';

.login-template {
  width: 100%;
  min-height: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-image: url('/img/login/login_back.png');
  background-repeat: no-repeat;
  background-size: 100%;
  color: $text-color;

  .login-background,
  .login-contents {
    position: relative;
    width: inherit;
    height: inherit;
  }

  &.saxa {
    color: $saxa-text-color;

    input,
    .main-text {
      color: $saxa-text-color;

      &::placeholder {
        color: $saxa-text-color;
      }
    }

    a:not(.service-link) {
      color: $saxa-brand-color !important;
    }

    .login-wrapper {
      .brand-color {
        color: $saxa-brand-color !important;
      }
    }

    .basic-login {
      .email-checkbox {
        .ant-checkbox-wrapper {
          color: $saxa-text-color !important;

          .ant-checkbox {
            .ant-checkbox-inner {
              border: none !important;
            }
          }
        }
      }
    }

    .already-login {
      .already-login-main-text,
      .already-login-sub-text {
        color: $saxa-text-color !important;
      }

      .already-login-btn.brand {
        background-color: $saxa-brand-color;
      }
    }

    .service-checkbox {
      .ant-checkbox-wrapper {
        color: $saxa-text-color !important;
      }
    }

    .ant-checkbox-wrapper {
      .ant-checkbox {
        .ant-checkbox-inner {
          background-color: #afafaf !important;
          border: 2px solid transparent !important;
        }
      }

      .ant-checkbox-checked {
        &::after {
          border-color: transparent !important;
        }
      }
    }

    .input-label {
      color: $saxa-text-color;
    }

    .footer-item {
      a {
        color: #ffffff !important;
      }
    }

    .button-box {
      button {
        color: #ffffff;
      }
    }

    .login-info-sub-text {
      color: #333333 !important;
      margin-top: 35px;
    }

    .border-box {
      color: $saxa-text-color;
      border: 1px solid $saxa-text-color;
    }
  }
}

.login-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &.column {
    flex-direction: column;
  }
}

.login-item {
  min-width: 350px;
  text-align: center;
}

.info-text {
  .main-text {
    color: #ddd;
    font-size: 18px;
    font-weight: 700;
  }

  // .sub-text {
  //   color: #afafaf;
  // }
}

.content-box {
  margin: 35px 45px 45px;
  text-align: center;
}

.border-box {
  margin-top: 23px;
  padding: 8px 15px;
  color: #ddd;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  border: 1px solid #ddd;
  border-radius: 3px;
  overflow: hidden;

  .text {
    margin-right: 5px;
  }
}

.button-box {
  display: flex;
  justify-content: center;
  margin-top: 33px;
  text-align: center;
}

.resend-result {
  color: red;
  font-size: 15px;
  font-weight: 700;
  margin-top: 20px;

  &.success {
    color: #3cb482;
  }
}

// 로그인 페이지 공통 상단 로고
.logo-white {
  width: 132px;
}

// 기본 로그인 화면
.basic-login {
  display: flex;
  width: 790px;

  .login-item {
    text-align: left;

    &.input-box {
      text-align: center;
      padding-left: 95px;
      width: 100%;
    }
  }

  .login-info {
    border-right: 1px solid #6e6e6e;

    .login-logo-box {
      display: flex;
      align-items: flex-end;
      width: 225px;
      min-height: 109px;

      .login-info-logo {
        max-width: 100%;
      }
    }

    .login-info-main-text {
      margin: 35px 0 10px;
      color: #ddd;
      font-size: 21px;
      font-weight: 700;

      &.ja {
        font-size: 19px;
      }
    }

    .login-info-sub-text {
      color: #888;
      font-weight: 700;
    }
  }

  .login-form {
    width: inherit;

    .underline-input {
      width: 300px;
      height: 45px;
      text-indent: 10px;
    }

    .error-message {
      margin-left: 20px;
    }

    .password-area {
      position: relative;
      display: flex;
      align-items: center;

      .underline-input {
        margin-bottom: 0;
        padding-right: 35px;
      }

      .lock-icon {
        position: absolute;
        right: 30px;
        cursor: pointer;
      }
    }
  }

  .email-checkbox {
    margin: 10px 0 15px;
    margin-left: 23px;
    text-align: left;

    .ant-checkbox-wrapper {
      color: $text-color;
      .ant-checkbox {
        .ant-checkbox-inner {
          background-color: #726e6b;
          border-color: #424242 !important;
        }
      }

      .ant-checkbox-checked {
        &::after {
          border-color: #424242;
        }

        .ant-checkbox-inner {
          border-radius: 3px;

          &::after {
            border-color: rgba(0, 0, 0, 0.95);
          }
        }
      }
    }
  }

  .service-box {
    display: flex;
    width: 100%;
    text-align: center;

    .service-item {
      flex: 1;
      border-right: 2px solid #726e6b;

      &:last-child {
        border-right: none;
      }

      .service-link {
        display: inline-block;
        width: 100%;
        // color: #afafaf;
        font-size: 13px;
        outline: none;
      }
    }
  }
}

// 최초 로그인 화면
.first-login {
  .login-info {
    display: flex;

    .logo {
      width: 42px;
      height: 42px;
      margin-right: 10px;
    }

    .sub-text {
      margin-top: 5px;
      text-align: left;
    }
  }

  .first-login-form {
    display: flex;
    flex-direction: column;
    padding: 45px 12px;

    .success-content {
      padding: 0 12px 30px;
      color: #00ad7b;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
    }

    .button-box {
      .first-login-btn {
        width: 120px;
        height: 30px;
        margin: 0 2px;
        font-weight: 700;
        // color: #afafaf;
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid #afafaf;

        &.brand {
          color: #00ad7b;
          border: 1px solid #00ad7b;
        }
      }
    }
  }
}

// 로그인 되어있을 시 안내 화면
.already-login {
  .already-login-main-text {
    font-size: 18px;
    color: #ddd;
  }

  .already-login-sub-text {
    margin-top: 15px;
    font-size: 12px;
    color: #afafaf;
  }

  .already-login-btn {
    width: 100%;
    height: 40px;
    margin: 0 3px;
    color: #fff;
    font-weight: 700;
    background-color: #6e6e6e;
    border: none;
    border-radius: 3px;

    &.brand {
      background-color: #00ad7b;
    }
  }
}

// 비활성 로그인 화면
.no-active-login {
  text-align: center;

  .period-info {
    text-align: left;
    margin: 5px 0 0 15px;
    font-size: 12px;

    .text {
      margin-right: 5px;
    }
  }

  .button-box {
    flex-direction: column;
    align-items: center;
    margin: 33px 15px;

    .login-btn {
      margin: 5px 0;
    }
  }
}

// 서비스 가입
.register-page {
  // height: auto;
  // color: #afafaf;

  .logo-white {
    padding-top: 50px;
  }

  .register-form {
    .explain-text {
      text-align: left;
      font-size: 10px;
    }

    .service-checkbox {
      margin: 25px 0 10px;

      .check-box {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .ant-checkbox-wrapper {
          display: flex;
          align-items: flex-start;
          color: $text-color;
          font-size: 12px;
          font-weight: 700;

          .ant-checkbox-checked {
            &::after {
              border-color: transparent !important;
            }

            .ant-checkbox-inner {
              background-color: #fff;

              &::after {
                top: 41%;
                left: 18%;
                border-color: #525150;
                transition: none;
              }
            }
          }

          .ant-checkbox {
            top: 1px;

            & + span {
              text-align: left;
              display: inline-block;
            }
          }

          .ant-checkbox-inner {
            background-color: transparent;
            border: 2px solid #fff;
          }
        }

        a {
          line-height: 1;
          color: #ddd;
          font-size: 12px;
          font-weight: 700;
          border-bottom: 2px solid #ddd;
        }
      }
    }

    .error-message {
      margin-bottom: 25px;
    }

    .button-box {
      flex-direction: column;

      .login-btn {
        margin: 0 auto;
        width: 300px;
        height: 50px;
      }

      .to-login {
        margin-top: 45px;

        a {
          margin-left: 15px;
          color: #00ad7b;
          text-decoration: underline;
        }
      }
    }
  }

  .register-complate {
    width: 566px;

    .content-box {
      margin: 35px 45px 45px;
      text-align: center;
    }

    .info-text {
      text-align: center;

      .main-text {
        font-size: 27px;
        font-weight: normal;
      }

      .sub-text {
        margin-top: 15px;

        div {
          font-size: 13px;
        }
      }
    }

    .border-box {
      width: 310px;
      margin: 30px auto 0;
    }

    .button-box {
      margin: 15px 0;

      .login-btn {
        width: 238px;
        height: 44px;
        box-shadow: 3px 3px 4px #333;
        background-color: #00ad7b;
      }
    }
  }
}

// 비밀번호 찾기
.password-reset-page {
  text-align: center;

  .content-box {
    text-align: left;

    &.send {
      text-align: center;
    }

    .passwd-reset-icon {
      margin-right: 5px;
    }

    .info-text {
      .main-text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
      }

      .sub-text {
        margin-top: 15px;
        font-size: 12px;
      }
    }

    .password-reset-form {
      margin-top: 25px;
      display: flex;
      flex-direction: column;

      .underline-input {
        width: 250px;
        text-indent: 10px;
      }

      .error-message {
        margin-left: 10px;
      }
    }
  }

  .button-box {
    margin: 33px auto;
    flex-direction: column;

    .login-btn {
      margin: 5px auto;
      width: 238px;
      height: 44px;
    }
  }
}

// 로그인 부가 인증
.otp-page {
  .sub-text {
    margin-top: 15px;
    font-size: 14px;
  }

  .otp-remain-time {
    color: #db2828;
    font-size: 13px;
    margin-top: 5px;
  }

  .otp-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;

    .error-message {
      width: 100%;
    }

    .button-box {
      flex-direction: column;
      margin-top: 0;

      .login-btn {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

// .login-page {
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: calc(100% - 100px);

//   .login-item {
//     min-width: 350px;
//   }

//   .button-box {
//     display: flex;
//     justify-content: center;
//     margin-top: 33px;
//     padding: 10px 0;
//     text-align: center;
//   }

//   // 기본 로그인 화면
//   .basic-login {
//     display: flex;
//     width: 790px;

//     .login-item {
//       &.input-box {
//         text-align: center;
//         padding-left: 95px;
//       }
//     }

//     .login-info {
//       padding-top: 30px;
//       border-right: 1px solid #6e6e6e;

//       .login-info-logo {
//         max-width: 100%;
//       }

//       .login-info-main-text {
//         margin: 40px 0 15px;
//         color: #ddd;
//         font-size: 21px;
//         font-weight: 700;
//       }

//       .login-info-sub-text {
//         margin-top: 20px;
//         color: #888;
//         font-weight: 700;
//       }
//     }

//     .login-form {
//       width: inherit;

//       .underline-input {
//         width: 300px;
//         height: 45px;
//         text-indent: 10px;
//       }

//       .error-message {
//         margin-left: 20px;
//       }
//     }

//     .email-checkbox {
//       margin: 10px 0 15px;
//       margin-left: 20px;
//       text-align: left;

//       .ant-checkbox-wrapper {
//         color: #afafaf;

//         .ant-checkbox {
//           .ant-checkbox-inner {
//             background-color: #726e6b;
//             border-color: #424242;
//           }
//         }

//         .ant-checkbox-checked {
//           &::after {
//             border-color: #424242;
//           }

//           .ant-checkbox-inner {
//             border-radius: 3px;

//             &::after {
//               border-color: rgba(0, 0, 0, 0.95);
//             }
//           }
//         }
//       }
//     }

//     .service-box {
//       display: flex;
//       width: 100%;
//       margin-top: 15px;
//       text-align: center;

//       .service-item {
//         flex: 1;

//         &:first-child {
//           border-right: 2px solid #726e6b;
//         }

//         .service-link {
//           display: inline-block;
//           width: 100%;
//           color: #afafaf;
//           font-size: 13px;
//           outline: none;
//         }
//       }
//     }
//   }

//   // 최초 로그인 화면
//   .first-login {
//     .first-login-info {
//       display: flex;
//       color: #ddd;
//       font-size: 18px;

//       .first-login-info-logo {
//         width: 42px;
//         height: 42px;
//         margin-right: 10px;
//       }

//       .first-login-main-text {
//         color: #ddd;
//         font-size: 18px;
//         font-weight: 700;
//       }

//       .first-login-sub-text {
//         margin-top: 5px;
//         color: #afafaf;
//       }
//     }

//     .first-login-form {
//       display: flex;
//       flex-direction: column;
//       padding: 45px 12px;

//       .success-content {
//         padding: 0 12px 30px;
//         color: #00ad7b;
//         font-size: 18px;
//         font-weight: 700;
//         text-align: center;
//       }

//       .button-box {
//         .first-login-btn {
//           width: 120px;
//           height: 30px;
//           margin: 0 2px;
//           font-weight: 700;
//           color: #afafaf;
//           background-color: transparent;
//           border-radius: 5px;
//           border: 1px solid #afafaf;

//           &.brand {
//             color: #00ad7b;
//             border: 1px solid #00ad7b;
//           }
//         }
//       }
//     }
//   }

//   // 로그인 되어있을 시 안내 화면
//   .already-login {
//     padding: 35px 45px;

//     .already-login-info {
//       text-align: center;

//       .info-text {
//         margin-top: 35px;
//       }

//       .already-login-main-text {
//         font-size: 18px;
//         color: #ddd;
//       }

//       .already-login-sub-text {
//         margin-top: 15px;
//         font-size: 12px;
//         color: #afafaf;
//       }
//     }

//     .already-login-btn {
//       width: 100%;
//       height: 40px;
//       margin: 0 3px;
//       color: #fff;
//       font-weight: 700;
//       background-color: #6e6e6e;
//       border: none;
//       border-radius: 3px;

//       &.brand {
//         background-color: #00ad7b;
//       }
//     }
//   }

//   // 비활성 로그인 화면
//   .no-active-login {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     height: 100%;
//     margin: 0 auto;
//     color: #afafaf;
//     text-align: center;
//   }
// }
