.start-edu {
  .no-tag-area {
    margin-top: 5px;
    padding: 10px 15px;
    border-radius: 5px;
    color: #ff1744;
    border: 1px solid #ff1744;

    .no-tag-main-text {
      font-weight: 700;

      .anticon-exclamation-circle {
        margin-right: 5px;
      }
    }

    .no-tag-sub-text {
      margin-top: 5px;
      font-size: 12px;
    }
  }

  .exam-tag {
    .tag-select-option {
      color: #000;

      .tag-circle {
        width: 17px;
        height: 17px;
        margin-right: 12px;
        border-radius: 9px;
      }

      .tag-name {
        flex: 1;
        word-break: break-all;
      }

      .tag-count {
        width: 50px;
        text-align: right;
      }
    }
  }

  .ant-select {
    font-size: 13px;
  }

  .modal-checkbox {
    margin: 10px 0 20px;
    color: #717171;
    font-size: 13px;
  }

  .anticon {
    margin-right: 0;
  }

  .multi-select {
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }

  .default-tag {
    padding: 2px 7px;

    .ant-tag-close-icon {
      display: block !important;
    }
  }

  .license-error {
    margin-top: 15px;

    .license-error-content {
      position: relative;
      color: #c14e38;
      font-size: 15px;

      .license-error-text {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-right: 5px;
        }
      }

      .license-error-popup {
        position: absolute;
        right: -310px;
        bottom: -25px;
        width: 300px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #909090;
        z-index: 10;

        .popup-header {
          padding: 15px 25px;
          color: #fff;
          font-size: 15px;
          background: linear-gradient(to right, #ff5656 0%, #b13838 100%);
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }

        .popup-content {
          padding: 15px;

          .license-status-row {
            display: flex;
            font-size: 13px;

            .license-name {
              margin-top: 6px;
              font-weight: 700;
            }

            .license-detail {
              display: flex;
              justify-content: space-between;
              padding: 5px 8px;

              .license-number {
                min-width: 40px;
              }

              .license-target {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }
}
