.profile-button-box {
  margin-top: 40px;
  display: flex;

  button {
    min-width: 125px;
    height: 45px;
    margin: 0 5px;
    color: #bbbbbb;
    font-weight: 700;
    background-color: #fff;
    border: 1px solid #bbbbbb;
    border-radius: 23px;

    &.ok {
      color: #fff;
      background-color: #11a991;
      border-color: #fff;
      border: 1px solid #11a991;
    }

    &.grey {
      background-color: #787878;
      color: #fff;
      border: 1px solid #787878;
    }

    &:hover {
      box-shadow: 1px 1px 5px #616161;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  .profile-save-box {
    flex: 1 1;
  }

  .profile-passwd-box {
  }
}

.change-email {
  display: flex;
  margin-top: -13px;

  .change-email-message {
    font-size: 12px;
    color: #14ae96;
    text-indent: 10px;
    margin-bottom: 23px;
    margin-right: 10px;
  }

  button {
    height: 23px;
    border-radius: 25px;
    border: none;
    background-color: #11a991;
    color: white;
    font-size: 11px;
    padding: 0 10px;
    margin: 0 3px;
    margin-top: -2px;
  }

  .bt-red {
    background-color: #5e5e5e !important;
  }
}

.leave-service {
  width: 152px;
  height: 46px;
  position: absolute;
  bottom: 30px;
  left: 50px;
  color: #fff;
  font-weight: 700;
  background-color: #333;
  border: none;
  border-radius: 5px;
}

.leave-modal {
  .modal-border-box {
    padding: 10px 20px !important;
  }
}
