.edu-main {
  width: 100%;
  height: 100%;

  div {
    letter-spacing: -0.5px !important;
  }

  form {
    position: relative;
  }

  .edu-layout {
    display: inline-flex;
    flex: 1;
    width: 100%;
    height: 100%;
    min-width: 1230px;
    overflow: hidden;

    // 교육 로그인 페이지
    .layout-left {
      float: left;
      position: relative;
      width: 45%;
      height: 100%;
      background: #ff9e46;

      .layout-left-box {
        position: absolute;
        top: 50%;
        right: 0;
        height: 480px;
        display: flex;
        align-items: center;
        margin-top: -241px;

        &.find-password {
          .login-text {
            font-size: 23px;
          }
          .arrow {
            left: 30px;
          }
        }

        .login-bg {
          width: 283px;
          box-shadow: -2px 2px 15px 0px #ea9344;
        }

        .login-text {
          position: absolute;
          left: 30px;
          font-size: 36px;
          font-weight: 700;
          color: #ffffff;
        }

        .arrow {
          position: absolute;
          left: 97px;
          top: 262px;
        }
      }
    }

    .layout-right {
      width: 55%;
      float: right;
      background-color: #fff;

      .r-logo {
        position: absolute;
        right: 30px;
        top: 30px;
      }

      .layout-right-box {
        position: absolute;
        top: 50%;
        width: 470px;
        height: 480px;
        margin-top: -240px;
        padding-left: 68px;
        background: #fff;
        box-shadow: 2px 3px 20px 0px #dddddd4d;

        .edu-title {
          width: 320px;
          padding-top: 60px;
          font-weight: 400;
          font-size: 20px;
          color: #000;
        }

        .edu-form-box {
          position: relative;
          padding-top: 28px;

          .text-field-title {
            color: #000;
            margin-top: 5px;
          }

          .form-wrap {
            width: 320px;

            input {
              display: flex;
              align-items: center;
              height: 50px;
              line-height: 50px;
              margin-bottom: 10px;
              padding: 0 12px;
              background-color: #f7f7f7;
              border: none;
              text-indent: 0;

              &::placeholder {
                color: #afafaf;
              }
            }

            input[type='password'] {
              padding-right: 35px;
            }
          }

          .edu-error-message {
            display: flex;
            align-items: center;
            line-height: 15px;
            // padding: 10px 0 5px;
            padding-bottom: 5px;
            color: #dd602a;
            font-size: 13px;
            letter-spacing: 0;

            img {
              height: 15px;
              padding-right: 5px;
            }
          }
        }

        .edu-button-box {
          position: absolute;
          top: 185px;

          &.setpassword {
            top: 230px;
          }

          button {
            width: 220px;
            height: 50px;
            line-height: 44px;
            font-size: 16px;
            text-align: center;
            border-radius: 25px 25px;
            border: 2px solid transparent;

            &.btn-orange {
              color: #fff;
              background-color: #f77339;
            }

            &.btn-white {
              margin-top: 12px;
              color: #a9a9a9;
              background-color: #fff;
              border: 2px solid #f2f2f2;
            }
          }
        }
      }
    }

    // 교육 메인 페이지
    .edu-play-left {
      width: 290px;
      height: 100%;
      background: linear-gradient(to bottom, #ff6635 0%, #ffd557 100%);
      position: relative;
      overflow: hidden;

      .bg-effect {
        width: 290px;
        overflow: hidden;

        .effect-1 {
          display: block;
          width: 0;
          height: 0;
          border-top: 215px solid #fff;
          border-left: 0px solid transparent;
          border-right: 186px solid transparent;
          opacity: 0.1;
        }

        .effect-2 {
          position: absolute;
          top: -193px;
          display: block;
          width: 120px;
          height: 0;
          border-bottom: 680px solid #fff;
          border-left: 30px solid transparent;
          border-right: 30px solid transparent;
          transform: rotate(-126deg);
          opacity: 0.1;
          z-index: 1;
        }

        .effect-3 {
          position: absolute;
          top: 56px;
          left: 52px;
          display: block;
          width: 234px;
          height: 0;
          border-bottom: 679px solid #fff;
          border-left: 56px solid transparent;
          border-right: 2px solid transparent;
          transform: rotate(-60deg);
          opacity: 0.05;
          overflow: hidden;
        }
      }

      .list-box {
        position: absolute;
        top: 0;
        z-index: 9;
        width: 100%;
        height: 100%;
        overflow-y: auto;

        .list-top {
          height: 86px;
          line-height: 86px;
          margin: -3px 0 0;
          padding: 0 0 0 27px;
          color: #fff;
          font-size: 23px;
          font-weight: 900;

          &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 8px;
            height: 8px;
            margin-right: 11px;
            border-radius: 50% 50%;
            background: #fff;
          }
        }

        .list-item {
          position: relative;
          width: 268px;
          height: auto;
          margin: 0 auto 14px;
          padding: 24px 18px 35px;
          color: #fff;
          border-radius: 25px 25px;
          z-index: 1;
          background: rgba($color: #fff, $alpha: 0.1);
          cursor: pointer;

          &.active {
            background: #fff;
            color: #000;

            .list-item-title {
              &::before {
                background: #ff7c3d;
              }
            }
          }

          &:hover:not(.active) {
            background: rgba($color: #fff, $alpha: 0.2);
          }

          .list-item-title {
            // display: flex;
            line-height: 20px;
            font-size: 15px;

            &::before {
              content: '';
              position: relative;
              top: -3px;
              display: inline-block;
              width: 5px;
              height: 5px;
              border-radius: 50% 50%;
              margin-right: 5px;
              background: #fff;
            }

            .complete {
              display: inline-block;
              position: relative;
              top: -2px;
              min-width: 40px;
              line-height: 20px;
              margin-right: 5px;
              padding: 0 5px;
              background-color: #565656;
              border-radius: 20px 20px;
              color: #fff;
              font-size: 11px;
              text-align: center;
            }
          }

          dl {
            margin: 15px 0 0;
          }

          .list-label {
            display: inline-block;
            min-width: 65px;
            line-height: 25px;
            margin: 0 7px 7px 0;
            padding: 0 10px;
            background-color: #ffe4ba;
            color: #763d10;
            font-size: 12px;
            text-align: center;
            font-weight: 700;
            border-radius: 20px 20px;
            letter-spacing: -1px;
          }

          .list-value {
            display: inline-block;
            line-height: 25px;
            margin-bottom: 7px;
            font-size: 13px;
          }

          .list-video-box {
            display: none;
            margin: 0;

            &.active {
              display: block;
            }

            .list-video {
              margin: 0;
              padding: 0 0 0 20px;

              .video-text {
                line-height: 20px;
                font-size: 13px;
              }
            }
          }

          .edu-list-arrow {
            position: absolute;
            bottom: 15px;
            width: 30px;
            height: 20px;
            left: 50%;
            text-align: center;
            margin-left: -15px;

            &:hover {
              transform: scale(1.5);
            }

            &.open {
              img {
                transform: rotate(0deg);
              }
            }

            img {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .edu-play-right {
      position: relative;
      display: inline-flex;
      flex: 1;
      flex-direction: column;
      width: calc(100% - 290px);

      .edu-play-header {
        background-color: #353230;
        position: relative;

        .edu-play-title {
          display: flex;
          align-items: center;
          width: calc(100% - 200px);
          height: 65px;
          padding-left: 50px;
          color: #fff;
          font-size: 17px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          &::before {
            display: inline-block;
            content: '';
            width: 14px;
            height: 14px;
            margin-top: 1px;
            margin-right: 8px;
            border-radius: 50% 50%;
            border: 3px solid #ff460b;
            vertical-align: middle;
          }
        }

        .my-info {
          position: absolute;
          right: 2%;
          top: 50%;
          margin-top: -20px;
          text-align: right;
          cursor: pointer;

          img {
            margin-right: 12px;
            vertical-align: top;
          }

          .name {
            display: inline-block;
            width: 100px;
            line-height: 40px;
            text-align: right;
            color: #fff;
            font-size: 14px;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            overflow: hidden;
          }
        }
      }

      .video-info {
        position: relative;
        padding: 40px 0 40px 72px;
        background-color: #f9f9f9;

        .video-name {
          display: inline-block;
          margin-bottom: 25px;
          color: #404040;
          font-size: 25px;
        }

        .edu-status {
          display: inline-block;
          position: relative;
          top: -4px;
          width: 90px;
          height: 37px;
          line-height: 33px;
          margin-left: 15px;
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          background: transparent;
          border-radius: 25px 25px;
          box-shadow: 3px 3px 20px 1px #e6e6e6;

          &.wait {
            color: #9b9b9b;
            border: 3px solid #9b9b9b;
          }

          &.ing {
            color: #fc793a;
            border: 3px solid #fc793a;
          }

          &.finish {
            color: #c62f00;
            border: 3px solid #c62f00;
          }
        }

        .summary-item {
          margin: 5px 0;

          &.percent {
            .edu-progress-bar {
              display: inline-block;
              vertical-align: middle;
              width: 298px;
              height: 5px;
              background-color: #ececec;

              .progress-percent {
                height: 12px;
              }
            }

            .percent-text {
              vertical-align: middle;
              font-size: 28px;
              margin-left: 15px;
            }
          }

          .summary-label {
            display: inline-block;
            vertical-align: middle;
            width: 83px;
            line-height: 29px;
            margin-right: 15px;
            color: #fff;
            font-size: 14px;
            text-align: center;
            background: #8a7c76;
            border-radius: 20px 20px;
            box-shadow: 0 0 15px 0px #e6e6e6;
            letter-spacing: -0.5px;
          }

          .summary-value {
            &.period {
              margin-right: 60px;
            }
          }
        }
      }

      .video-tab {
        border-bottom: 2px solid #f6f6f6;

        .tab-list {
          margin: 0;

          .tab-item {
            display: inline-block;
            height: 55px;
            line-height: 55px;
            margin-right: 70px;
            color: #cdcdcd;
            font-size: 15px;
            cursor: default;

            &:first-child {
              margin-left: 25px;
            }

            &.on {
              color: #ff854d;
              border-bottom: 4px solid #ff854d;
            }
          }
        }
      }

      .video-player {
        flex: 1;
        padding: 30px 50px;

        .video-js {
          width: 100%;
          height: 100%;

          .vjs-subs-caps-button {
            display: none;
          }
        }
      }

      .video-quiz {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        overflow-y: auto;

        .quiz-contents {
          display: flex;
          flex-direction: column;
          // align-items: center;
          // justify-content: center;
          height: 80%;
          margin: auto 0;

          .quiz-text {
            width: 700px;
            min-height: 2em;
            max-height: 4.2em;
            line-height: 1.4;
            padding: 5px;
            font-size: 25px;
            font-weight: 700;
            word-break: break-all;
            overflow-y: auto;
          }

          .quiz-answer {
            width: 700px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin: 20px 0;

            .btn-o,
            .btn-x {
              width: 230px;
              height: 230px;
              display: flex;
              align-items: center;
              border: 1px solid #dddddd;
              border-radius: 10px;
              cursor: pointer;

              img {
                width: 148px;
                height: 146px;
                margin: auto;
              }

              &.selected {
                box-shadow: 0px 0px 10px 10px inset #dddddd;
              }
            }
          }
        }

        .next-quiz-btn {
          position: relative;
          right: -100%;
          width: 55px;
          padding: 5px 0;
          color: #ffffff;
          background-color: #dddddd;
          font-size: 15px;
          border: none;

          &::after {
            position: absolute;
            content: '\A';
            top: -20%;
            left: 50px;
            border-style: solid;
            border-width: 22px 0 22px 20px;
            border-color: transparent transparent transparent #dddddd;
          }

          &.active {
            background-color: #fc793a;

            &::after {
              border-color: transparent transparent transparent #fc793a;
            }
          }
        }
      }
    }

    .edu-progress-bar {
      position: relative;
      top: 10px;
      width: 233px;
      height: 4px;
      margin-bottom: 20px;
      background-color: #eaeaea;
      border-radius: 25px 25px;

      .progress-percent {
        position: absolute;
        top: -3px;
        left: 0;
        height: 10px;
        background: linear-gradient(to left, #ff6635 0%, #fec54b 100%);
        border-radius: 25px 25px;
      }
    }
  }
}

.edu-play-popover {
  .ant-popover-content {
    max-width: 100% !important;

    .ant-popover-inner {
      box-shadow: 0px 0px 15px #d2d2d2;
    }
  }

  .edu-my-box {
    position: relative;

    .edu-target-box {
      position: absolute;
      top: 0;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      img {
        width: 44px;
        height: 44px;
        margin-right: 14px;
      }

      .edu-target-info {
        div {
          line-height: 22px;
          color: #fff;
          letter-spacing: 0;
        }

        .edu-target-name {
          font-size: 15px;
          font-weight: 700;
        }

        .edu-target-id {
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }

  .logout-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 14px;
    font-size: 13px;
    color: #adadad;

    button {
      padding: 0 10px 0 5px;
      border: 0;
      outline: none;
      background: transparent;

      img {
        position: relative;
        bottom: 1px;
        margin-right: 3px;
      }
    }
  }
}

.edu-play-tab-label {
  width: 65px;
  height: 23px;
  line-height: 21px;
  margin: 10px 0 0;
  font-size: 12px;
  text-align: center;
  border-radius: 25px 25px;

  &.before {
    border: 1px solid #a3a3a3;
  }
  &.ing {
    color: #ff8147;
    border: 1px solid #ff8147;
  }
  &.finish {
    color: #c62f00;
    border: 1px solid #c62f00;
  }
}

.declare-banner {
  position: relative;
  width: 800px;

  .declare-text {
    position: absolute;
    top: 0;
    left: 160px;
    height: 92px;
    display: flex;
    align-items: center;
    margin-top: 2px;
  }

  .main-text {
    color: #252a3c;
    font-size: 28px;
    font-weight: bold;

    .point {
      color: #ce2200;
    }
  }

  .sub-text {
    margin-left: 15px;
    font-size: 14px;
  }

  .go-text {
    position: absolute;
    right: 17px;
    bottom: 16px;
    color: #ac2800;
    font-size: 10px;
    font-weight: bold;
  }
}
